import React from 'react'
import './experience.css';
import {BsPatchCheckFill} from 'react-icons/bs';

const Experience = () => {
  return (
    <section id='experience'>
      <h5>My Skills</h5>
      <h2>My Experience</h2>
      <div className="container experience__container">
        <div className="experience__front-end">   
          <h3>Front End Development / Tools</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__detail-icon' />
              <div>
                <h4>HTML</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__detail-icon' />
              <div>
                <h4>CSS, SCSS, Sass</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__detail-icon' />
              <div>
                <h4>JavaScript</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__detail-icon' />
              <div>
                <h4>Bootstrap</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__detail-icon' />
              <div>
                <h4>Tailwind</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__detail-icon' />
              <div>
                <h4>React</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__detail-icon' />
              <div>
                <h4>jQuery</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__detail-icon' />
              <div>
                <h4>Material UI</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__detail-icon' />
              <div>
                <h4>Git</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__detail-icon' />
              <div>
                <h4>Bitbucket</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__detail-icon' />
              <div>
                <h4>Docker</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
          </div>
        </div>
        {/* END OF FRONTEND */}
        <div className="experience__backend">
        <h3>Back End Development / CMS</h3>
          <div className="experience__content">
            {/* <article className='experience__details'>
              <BsPatchCheckFill className='experience__detail-icon' />
              <div>
                <h4>Java</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article> */}
            {/* <article className='experience__details'>
              <BsPatchCheckFill className='experience__detail-icon'/>
              <div>
                <h4>MongoDB</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article> */}
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__detail-icon' />
              <div>
                <h4>PHP</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__detail-icon' />
              <div>
                <h4>MySQL</h4>
                <small className='text-light'>Basic</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__detail-icon' />
              <div>
                <h4>Python</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article> 
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__detail-icon' />
              <div>
                <h4>MSSQL</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article> 
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__detail-icon' />
              <div>
                <h4>OracleDB</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article> 
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__detail-icon' />
              <div>
                <h4>Firebase</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article> 
            {/* <article className='experience__details'>
              <BsPatchCheckFill className='experience__detail-icon' />
              <div>
                <h4>Spring Boot</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>  */}
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__detail-icon' />
              <div>
                <h4>WordPress</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article> 
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__detail-icon' />
              <div>
                <h4>Node</h4>
                <small className='text-light'>Basic</small>
              </div>
            </article>                                                                                                   
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience