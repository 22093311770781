import React, {useRef} from 'react'
import './contact.css';
import {MdOutlineEmail} from 'react-icons/md';
import {RiMessengerLine} from 'react-icons/ri';
import {BsWhatsapp} from 'react-icons/bs';
import emailjs from 'emailjs-com';

const Contact = () => {
  const form = useRef();

  //THE ENV VARIABLES
  const phoneNumber = process.env.REACT_APP_PHONE;
  const servId = process.env.REACT_APP_SERVICE_ID;
  const template = process.env.REACT_APP_TEMPLATE_ID;
  const pubKey = process.env.REACT_APP_PUBLIC_KEY;

  //This sendmail function comess from emailjs doc @
  //https://www.emailjs.com/docs/examples/reactjs/
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(servId, template, form.current, pubKey)
    e.target.reset();
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className='contact__option-icon' />
            <h4>Email</h4>
            <h5>salankyjoe@gmail.com</h5>
            <a href="mailto:salankyjoe@gmail.com" target='_blank'>Send a message</a>
          </article>

          <article className="contact__option">
            <RiMessengerLine className='contact__option-icon' />
            <h4>Messenger</h4>
            <h5>Salako Joel Sunday</h5>
            <a href="https://m.me/salako.j.sunday" target='_blank'>Send a message</a>
          </article>

          <article className="contact__option">
            <BsWhatsapp className='contact__option-icon' />
            <h4>WhatsApp</h4>
            <h5>+123456789</h5>
            {/* <a href="https://api.whatsapp.com/send?phone+2348032004758" target='_blank'>Send a message</a> */}
            <a href={`whatsapp://send?phone=${phoneNumber}`} target='_blank'>Send a message</a>
          </article>
        </div>
        {/* END OF CONTACT OPTIONS */}
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required />
          <input type="email" name='email' placeholder='Your Email' required/>
          <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
          <button type='ssubmit' className="btn btn-primary">Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact